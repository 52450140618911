import { combineReducers } from "redux";

import popupDataReducer from "./popup/reducer";

const lastAction = (state = null, action) => action;

const rootReducer = combineReducers({
  popupDataReducer,
  lastAction
});

export default rootReducer;