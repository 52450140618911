import React, { useEffect, useState } from "react";
import { Outlet } from 'react-router-dom';
import HsTermsHeader from "./HsTermsHeader";
import HsTermsFooter from "./HsTermsFooter";
import styles from '../../styles/pages/HsTermsLayout.module.scss';

function HsTermsLayout() {
    const [termsTitle, setTermsTitle] = useState("");

    useEffect(() => {
        const { pathname } = window.location;
        switch(pathname) {
            case '/terms/service':
                setTermsTitle("이용약관");
                break;
            case '/terms/privacy':
                setTermsTitle("개인정보 처리방침");
                break;
            case '/terms/privacy200416':
                setTermsTitle("개인정보 처리방침");
                break;
            default:
                setTermsTitle("Default Title");
                break;
        }
    }, []);
    
    return (
        <>
            <h1 className="blind">휴머스온</h1>
            <HsTermsHeader title={ termsTitle } />
            <main className={styles['hs-terms-main']}>
                <div className={styles['hs-terms-main-inner']}>
                    <Outlet />
                </div>
            </main>
            <HsTermsFooter />
        </>
    );
}

export default HsTermsLayout;