import React from 'react';
import popup220318 from "../../assets/images/popup/popup_220318.jpg";
import styles from '../../styles/components/common/Popup.module.scss';


function Popup220318() {
  return (
<>
    <img src={popup220318} alt="신규 서비스 개편 안내" />
    <p className="blind">안녕하세요. 고객님. 여기저기 흩어져 있어서 불편했던 디지털 마케팅 솔루션과 서비스들. 이제는 마케팅 자동화, 통합메시징, 통신 3사 타겟 마케팅, 빅데이터 분석, AI 상품 추천까지 한 번에 타스온에 접속만 하시면, 이용하실 수 있습니다.</p>
    <p className="blind">상담 문의가 필요하시면, sales@humuson.com으로 언제든 문의 주세요. 감사합니다.</p>
    <a href="https://www.tason.com/" target="_blank" className={styles['hs-popup-body-link']}>바로가기</a>
</>
  );
}

export default Popup220318;