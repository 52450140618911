import React, { useState } from "react";
import styles from "../../styles/components/layout/HsFooter.module.scss";
import { Link } from 'react-router-dom';
import footerLogo from '../../assets/images/footer_logo_white.svg';
import { METHOD, SEND_STATE } from "../../assets/data/common";

function HsFooter() {
    const [sendState, setSendState] = useState(SEND_STATE.COMPLETED);
    const [showSubscribe, setShowSubscribe] = useState(true);
    const [email, setEmail] = useState('');

    const handleSubscribe = () => {
        const regExpEmail = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*[_\.]*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,}$/i;
        if(email == null) {
            alert("이메일을 입력해주세요.");
            return false;
        }

        if (!regExpEmail.test(email)) {
            alert("이메일 형식에 맞게 입력하세요.");
            return false;
        }

        setSendState(SEND_STATE.SENDING);
        const url = 'https://api-mkt.tason.com/newsletter';
        const method = METHOD.POST;
        const body = JSON.stringify({
            email,
        });
        fetch(url, {
            method,
            body
        }).then(res => res.json()).then((res) => {
            const { result_code } = res;
            switch(result_code) {
                case '41':
                    setShowSubscribe(false);
                    break;
                default:
                    break;
            }
        });
    }
    const gotoLink = (link) => {
        window.location.href = link;
    }

    return (
        <section>
            <footer className={styles['hs-footer']}>
                <div className={styles['hs-footer-inner']}>
                    {
                        showSubscribe && 
                            <div className={styles['hs-footer-subscribe-container']}>
                                <p>
                                    마케팅 인사이트와 업계 최신 트렌드를
                                    <br />
                                    타스온 뉴스레터를 통해 가장 먼저 만나보세요.
                                </p>
                                <div>
                                    <input 
                                        type="email" 
                                        required 
                                        placeholder="회사 이메일 주소를 입력하세요." 
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <button onClick={handleSubscribe}>{sendState}</button>
                                </div>
                            </div>
                    }
                    {
                        !showSubscribe && 
                            <div className={styles['hs-footer-subscribe-container']}>
                                <p className={styles.wide}>
                                    15,000명 이상의 업계 관계자들이 구독하고 있는
                                    뉴스레터를 통해 업계 최신 트렌드를 가장 먼저
                                    만나보세요.
                                </p>
                                <div>
                                    <input 
                                        type="text" 
                                        value={'응답이 제출되었습니다. 감사합니다.'}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                    }
                    <hr className={styles['hs-footer-divider']}></hr>
                    <div className={styles['hs-footer-company-info-area']}>
                        <div className={styles['hs-footer-company-logo-wrap']}>
                            <img src={footerLogo} alt="휴머스온" className={styles['hs-footer-company-logo']} />
                            {/* <strong className={styles['hs-footer-company-logo-title']}>(주)휴머스온</strong> */}
                        </div>
                        <div className={styles['hs-footer-company-info-list']}>
                            <dl>
                                (주)휴머스온 | 대표자 백동훈 | (주)휴머스온 서울시 강남구 선릉로 433 세방빌딩 10층,12층,18층
                                사업자등록번호 147-87-02929 | 팩스 02-6008-9270 | 전화번호 <span onClick={() => gotoLink('tel:025383776')}>02 538 3776</span>
                            </dl>
                            <div className={styles['hs-company-info']}>
                                <ul className={styles['hs-footer-terms-list']}>
                                    <li className={styles['hs-footer-terms-item']}>
                                        <Link to="/company" className={styles['hs-footer-terms-link']}>회사소개</Link>
                                    </li>
                                    <li className={styles['hs-footer-terms-item']}>
                                        <Link to="/terms/service" className={styles['hs-footer-terms-link']}>이용약관</Link>
                                    </li>
                                    <li className={styles['hs-footer-terms-item']}>
                                        <strong>
                                            <Link to="/terms/privacy" className={styles['hs-footer-terms-link']}>개인정보 처리방침</Link>
                                        </strong>
                                    </li>
                                </ul>
                                <span className={styles['hs-footer-copyright']}>&copy; Copyright humusOn Inc. All Rights Reserved.</span>
                            </div>
                        </div>

                        <ul className={styles['hs-footer-sns-list']}>
                            <li className={styles['hs-footer-sns-item']}>
                                <Link to="https://www.facebook.com/humuson.official" target="_blank" className={`${styles['hs-footer-sns-link']} ${styles['facebook']}`}>
                                    <span className="blind">페이스북 바로가기</span>
                                </Link>
                            </li>
                            <li className={styles['hs-footer-sns-item']}>
                                <Link to="https://blog.naver.com/tason_official" target="_blank" className={`${styles['hs-footer-sns-link']} ${styles['blog']}`}>
                                    <span className="blind">블로그 바로가기</span>
                                </Link>
                            </li>
                            <li className={styles['hs-footer-sns-item']}>
                                <Link to="http://blog.tason.com/" target="_blank" className={`${styles['hs-footer-sns-link']} ${styles['wordpress']}`}>
                                    <span className="blind">워드프레스 블로그 바로가기</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
        </section>
    );
}

export default HsFooter;