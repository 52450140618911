import React from 'react';
import styles from '../../styles/components/common/Textarea.module.scss';


function Textarea({ textareaLabel, textareaId, ...props }) {
  function handleChange(e) {
    const { value } = e.target;
    props.setValue && props.setValue(value);
  }
  return (
    <>
        <div className={styles['hs-textarea-container']} style={props.style}>
            { textareaLabel && <label className={styles['hs-textarea-label']} htmlFor={textareaId}>{textareaLabel}</label> }
            <textarea
              className={styles['hs-textarea']}
              id={textareaId}
              style={{ height: `${props.textareaHeight}` }}
              onChange={handleChange}
            >
                {props.textareaContents}
            </textarea>
        </div>
    </>
  );
}

export default Textarea;