import React, { useState, useEffect } from 'react';
import styles from '../../../styles/components/common/Popup.module.scss';
import PopupContent from './PopupContent';
import Button from '../Button';

function Popup({ ...props }) {
  const [formData, setFormData] = useState({});

  useEffect(() => {
    // const today = new Date().toISOString().slice(0, 10);
    // const isPopupShown = localStorage.getItem('isPopupShown');
    // const isPopupShownToday = localStorage.getItem('isPopupShownToday');
    
    // if (!isPopupShown && isPopupShownToday !== today) {
    //   setShowPopup(true);
    // }
  }, []);

  function handleDismissClick() {
    props.dismissClick && props.dismissClick();
  }

  function handleDismissClickForDay() {
    props.dismissClickForDay && props.dismissClickForDay();
  }

  function handleClose() {
    props.onClose && props.onClose();
  }

  function handleClick() {
    props.buttonPrimaryClick && props.buttonPrimaryClick();
  }

  return (
    <div className={`${styles['hs-popup-container']} ${styles['active']}`}>
      <div className={styles['hs-popup']}>
        <div className={styles['hs-popup-header']}>
          <h2 className={styles['hs-popup-header-title']}>{props.contentTitle}</h2>
        </div>
        <div className={`${styles['hs-popup-body']} ${props.contentImage && styles['image']}`}>          
          <PopupContent contentComponent={props.contentComponent} popupConstructor={props.popupConstructor} />
        </div>
        <div className={styles['hs-popup-footer']}>
          {
            props.buttonPrimary &&
            <Button
              buttonType="button"
              buttonPrimary
              buttonLabel={props.buttonPrimary}
              onClick={handleClick}
            />
          }
          {
            props.contentCheck &&
            <div className={styles['hs-popup-input-container']}>
            <label className={styles['hs-popup-input-label']}>
              <input type="checkbox" className={styles['hs-popup-input-check']} onClick={handleDismissClickForDay} />
              오늘 하루 열지 않기
            </label>
            <label className={styles['hs-popup-input-label']}>
              <input type="checkbox" className={styles['hs-popup-input-check']} onClick={handleDismissClick}  />
              다시 보지 않기
            </label>
          </div>
          }
        </div>
            <button type="button" className={styles['hs-popup-close-button']} onClick={handleClose}>
                <span className="blind">닫기</span>
            </button>
            </div>
        </div>
    );
}

export default Popup;
