import React from "react";
import styles from '../styles/pages/HsTermsLayout.module.scss';

function HsTermsService() {
    return (
        <>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>제1조(목적)</h3>
                <p className={styles['hs-terms-section-text']}>본 약관은 주식회사 휴머스온(이하 "회사")이 운영하는 웹사이트(이하 "사이트")를 통해 인터넷 관련 서비스를 제공함에 있어, 회사가 제공하는 서비스와 관련하여 이를 이용하는 가입자(이하 "회원")의 이용조건 및 제반 절차, 기타 필요한 사항을 규정함을 목적으로 합니다.</p>
            </section>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>제2조(이용약관의 효력 및 변경)</h3>
                <ol className={`${styles['hs-terms-section-list']}`}>
                    <li className={styles['hs-terms-section-list-item']}>① 본 약관은 사이트를 이용하고자 하는 모든 회원에 대하여 그 효력이 발생합니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>② 본 약관은 사이트에 공시됨으로써 효력이 발생되고, 합리적인 사유가 발생할 경우 회사는 관계법령에 위배되지 않는 범위에서 이 약관을 변경할 수 있습니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>③ 개정약관도 사이트에 공시됨으로써 효력이 발생됩니다. 회사는 약관을 변경할 경우 지체 없이 이를 공시하여야 하고, 요금 등 회원의 권리나 의무 등에 관한 중요사항을 개정할 경우에는 사전에 공지합니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>④ 본 약관에 동의하는 것은 정기적으로 사이트에 방문하여 약관의 변경사항을 확인하는 것에 동의함을 의미합니다. 변경된 약관을 알지 못해 발생하는 이용자의 피해는 회사에서 책임지지 않습니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>⑤ 약관에 동의하지 않는 회원은 탈퇴(해지)를 요청할 수 있으며, 약관의 효력이 발생된 날로부터 7일 이후까지 탈퇴 요청을 하지 않고 아니하고 사이트를 계속 사용할 경우는 약관에 동의한 것으로 간주됩니다.</li>
                </ol>
            </section>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>제3조(약관과 기타 준칙)</h3>
                <ol className={`${styles['hs-terms-section-list']}`}>
                    <li className={styles['hs-terms-section-list-item']}>① 본 약관은 회사가 제공하는 개별서비스에 관한 이용안내(이하 서비스별 안내라 함)와 함께 적용됩니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>② 이 약관에서 규정하지 않은 사항에 관해서는 약관의 규제 등에 관한 법률, 전기통신기본법, 전기통신사업법, 정보통신망이용촉진등에관한법률 등의 관계법령에 따릅니다.</li>
                </ol>
            </section>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>제4조(용어 정의)</h3>
                <ol className={`${styles['hs-terms-section-list']}`}>
                    <li className={styles['hs-terms-section-list-item']}>
                        ① "사이트"라 함은 "회사"가 서비스를 "회원" 에게 제공하기 위하여 단말기(PC, TV, 휴대형단말기 등의 각종 유무선 장치를 포함) 등 정보통신설비를 이용하여 설정한 가상의 영업장 또는 회사가 각각 운영하는 웹사이트를 말하며, 통합된 하나의 아이디 및 비밀번호(이하 "계정")를 이용하여 서비스를 제공받을 수 있는 아래의 사이트로 정의한다.
                        <ol className={styles['hs-terms-section-number-list']}>
                            <li className={styles['hs-terms-section-number-item']}>1. TAS : www.tason.com</li>
                        </ol>
                    </li>
                    <li className={styles['hs-terms-section-list-item']}>② "서비스"라 함은 "회사"가 기업 또는 개인에게 해당 인터넷 주소에서 제공하는 모든 부대 서비스를 말합니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>③ "회원"이라 함은 서비스를 이용하기 위하여 동 약관에 동의하고 회사와 이용계약을 체결하여 이용자ID를 부여 받은 개인을 말합니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>④ "이용자ID" 또는 "회원ID"라 함은 회원의 식별과 회원의 서비스 이용을 위하여 회원이 선정하고 회사가 부여하는 문자와 숫자의 조합을 말합니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>⑤ "비밀번호"라 함은 회사의 서비스를 이용하려는 사람이 이용자ID를 부여 받은 자와 동일인임을 확인하고 회원의 권익을 보호하기 위하여 회원이 선정한 문자와 숫자의 조합을 말합니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>⑧ "잔액"이란 정상적인 대금 결제를 통해 충전된 금액입니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>⑦ "마일리지"란 회사가 제공하는 유료서비스를 이용하거나 정상적인 대금 결제에 따라 지급되는 포인트입니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>⑧ "콘텐츠"란 회사가 제공하는 발송서비스를 회원이 이용하기 위해 사이트에 등록한 문자, 그림 등의 조합으로 생성된 제작물입니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>⑨ "전송자격인증제"란 전기통신사업법 제2조제14호나목의 특수한 유형의 부가통신역무를 제공하는 사업자가 영리 목적의 광고성 정보를 발송하는 경우 사전에 문자중계사 또는 인증업무를 위탁받은 운영기관이 심사를 통해 인증을 부여하는 제도를 말한다.</li>
                </ol>
            </section>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>제5조(이용 계약의 성립)</h3>
                <ol className={`${styles['hs-terms-section-list']}`}>
                    <li className={styles['hs-terms-section-list-item']}>① 회사의 서비스 이용계약(이하 "이용계약"이라 한다)은 서비스를 이용하고자 하는 자의 본 약관과 개인정보처리방침의 내용에 대한 동의 및 이용신청(회원가입신청)에 대하여 회사가 승낙함으로써 성립합니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>② 서비스를 이용하고자 하는 자가 회원인증 과정을 완료시 본 약관 및 개인정보처리방침에 대하여 동의한 것으로 간주합니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>③ 서비스의 대량이용 등 특별한 이용에 관한 계약은 별도 계약에 의하여 제공합니다.</li>
                </ol>
            </section>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>제6조(서비스 이용 신청)</h3>
                <ol className={`${styles['hs-terms-section-list']}`}>
                    <li className={styles['hs-terms-section-list-item']}>① 회원으로 가입하여 본 서비스를 이용하고자 하는 이용고객은 회원인증 방식(휴대폰 인증, 아이핀 인증)을 선택하여 회사에서 요청하는 제반정보(이름, 외국인등록번호, 여권번호, 생년월일, 전화번호, 휴대폰 번호 등)를 제공하여야 합니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>② 실명으로 등록하지 않은 사용자는 일체의 권리를 주장할 수 없습니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>③ 타인의 명의를 도용하여 이용신청을 한 회원의 모든 ID는 삭제 되며, 관계법령에 따라 처벌을 받을 수 있습니다.</li>
                </ol>
            </section>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>제7조 (이용 신청의 승낙과 제한)</h3>
                <ol className={`${styles['hs-terms-section-list']}`}>
                    <li className={styles['hs-terms-section-list-item']}>① 회사는 제6조의 규정에 의한 이용신청고객에 대하여 업무 수행상 또는 기술상 지장이 없는 경우 원칙적으로 접수순서에 따라 서비스 이용을 승낙합니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>
                        ② 회사는 아래사항에 해당할 경우 이용 승낙하지 않을 수 있습니다.
                        <ol className={styles['hs-terms-section-number-list']}>
                            <li className={styles['hs-terms-section-number-item']}>1. 이용자가 신청한 아이디가 이미 다른 이용자에 의해 쓰여지고 있는 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>2. 이용신청 시 타인명의의 사용 및 허위내용의 기재 등 정확하지 않은 정보를 사용하여 신청한 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>3. 이용자가 서비스의 정상적인 제공을 저해하거나 다른 이용자의 서비스 이용에 지장을 줄 것으로 예상되는 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>4. 신용정보의 이용과 보호에 관한 법률에 의하여 신용불량자로 등록되어 있는 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>5. 정보통신윤리위원회의 인터넷 서비스 불량이용자로 등록되어 있는 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>6. 이용신청 요건이 미비 되었거나 본인확인이 불가능할 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>7. 부정한 용도로 본 서비스를 이용하고자 하는 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>8. 본 서비스와 경쟁관계에 있는 이용자가 신청하는 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>9. 회사의 서비스 제공에 피해를 끼칠 수 있다고 판단 되는 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>10. 개인 또는 법인이 메시지 무료 발송을 위해 다수의 아이디로 가입하는 등 건전한 서비스 이용에 위배되는 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>11. 기타 규정한 제반 사항을 위반하며 신청하는 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>12. 전송자격인증제에 따른 전송자격 인증을 받지 않거나, 인증이 취소된 사업자의 경우</li>
                        </ol>
                    </li>
                    <li className={styles['hs-terms-section-list-item']}>
                        ③ 회사는 서비스 이용신청이 다음 각 호에 해당하는 경우에는 그 신청에 대하여 승낙 제한사유가 해소될 때까지 승낙을 유보할 수 있습니다.
                        <ol className={styles['hs-terms-section-number-list']}>
                            <li className={styles['hs-terms-section-number-item']}>1. 회사의 기술상 지장이 있는 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>2. 기타 이용승낙이 곤란한 사유가 있는 경우</li>
                        </ol>
                    </li>
                    <li className={styles['hs-terms-section-list-item']}>④ 회사는 이용신청고객이 관계법령에서 규정하는 미성년자일 경우에 서비스별 안내에서 정하는 바에 따라 승낙을 보류할 수 있습니다.</li>
                </ol>
            </section>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>제8조(서비스의 내용)</h3>
                <ol className={`${styles['hs-terms-section-list']}`}>
                    <li className={styles['hs-terms-section-list-item']}>
                        ① 회사는 회원에게 아래와 같은 서비스를 제공합니다.
                        <ol className={styles['hs-terms-section-number-list']}>
                            <li className={styles['hs-terms-section-number-item']}>1. 발송서비스(이메일, 문자(SMS/LMS/MMS), 카카오비즈메시지, 앱푸시, 웹푸시, 팩스)</li>
                            <li className={styles['hs-terms-section-number-item']}>2. 발송서비스와 관련된 제반서비스</li>
                            <li className={styles['hs-terms-section-number-item']}>3. 데이터분석 및 마케팅 자동화 서비스</li>
                            <li className={styles['hs-terms-section-number-item']}>4. 데이터분석 및 마케팅 자동화와 관련된 제반서비스</li>
                            <li className={styles['hs-terms-section-number-item']}>5. 회원간 광고거래 중개서비스</li>
                            <li className={styles['hs-terms-section-number-item']}>6. 디지털콘텐츠 제작대행 서비스</li>
                            <li className={styles['hs-terms-section-number-item']}>7. 기타 회사가 자체 개발하거나 다른 회사와의 협력계약을 통해 회원들에게 제공하는 일체의 서비스</li>
                        </ol>
                    </li>
                    <li className={styles['hs-terms-section-list-item']}>② 회사는 변경될 서비스의 내용 및 일자를 사이트를 통해 공지하고 서비스를 변경하여 제공할 수 있습니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>③ 주소록은 회사가 정한 기준에 따라 자동삭제 될 수 있습니다. 3개월 이상 발송 또는 수정 내역이 없을 경우 자동 삭제 됩니다. 삭제된 데이터는 회사에 책임이 없으며 이미 삭제된 데이터는 복구할 수 없습니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>④ 푸시 결제 후 설정한 시작 서비스 일부터 다음 결제일까지(미설정 시 입금확인 일부터) 30일 간격이며, 해당기간 경과 시 등록 된 푸시발송은 정지 되며 그 후 또다시 30일 경과 시까지 결제를 하지 않을 경우 등록 된 앱은 자동삭제 처리 됩니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>⑤ 마케팅 자동화를 위해 스크립트를 회원 웹 사이트 내 페이지에 설치(삽입)하는 작업은 서비스 제공 내역에 포함되어 있지 않습니다. 스크립트 설치 대행을 요청할 경우 설치 대행 요금을 청구할 수 있습니다.</li>
                </ol>
            </section>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>제9조 (개인정보의 보호 및 사용)</h3>
                <ol className={`${styles['hs-terms-section-list']}`}>
                    <li className={styles['hs-terms-section-list-item']}>① 회사는 고객의 개인정보를 보호하고 존중합니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>② 회사는 이용신청 시 고객이 제공하는 정보, 각종 이벤트 참가를 위하여 고객이 제공하는 정보, 기타 서비스 이용 과정에서 수집되는 정보 등을 통해 고객에 관한 정보를 수집하며, 수집된 고객의 정보는 본 이용계약의 이행과 본 이용계약상의 서비스 제공을 위한 목적으로 사용됩니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>③ 회사는 서비스 제공과 관련하여 취득한 개인정보를 본인의 승낙 없이 제3자에게 누설할 수 없습니다. 다만, 다음의 각 호의 경우는 제외합니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>
                        ① 회사는 회원에게 아래와 같은 서비스를 제공합니다.
                        <ol className={styles['hs-terms-section-number-list']}>
                            <li className={styles['hs-terms-section-number-item']}>1. 배송업무상 배송업체에게 배송에 필요한 최소한의 이용자의 정보(성명, 주소, 전화번호)를 알려주는 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>2. 도용방지를 위하여 본인확인에 필요한 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>3. 타인에게 법적인 피해를 주거나 미풍양속을 해치는 행위를 한 사람 등에게 법적인 조치를 취하기 위하여 개인정보를 공개해야 한다고 판단되는 충분한 근거가 있는 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>4. 보다 나은 서비스를 제공하기 위한 업무 제휴로 개인정보 공유가 불가피하고, 이에 대해 회원의 동의를 얻은 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>5. 서비스의 제공에 따른 요금 정산을 위하여 필요한 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>6. 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 알아볼 수 없는 형태로 가공하여 제공하는 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>7. 관계 법령에 의하여 수사상 목적으로 정해진 절차와 방법에 따라 관계기관의 요구가 있는 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>8. 다른 법률에 특별한 규정이 있는 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>9. 정보통신윤리위원회가 관계법령에 의하여 요청 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>10. 보다 전문적이고 다양한 서비스를 제공하기 위한 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>11. 특정 개인을 식별할 수 없게 재가공된 마케팅 정보</li>
                        </ol>
                    </li>
                    <li className={styles['hs-terms-section-list-item']}>④ 회사는 회원에게 다양한 서비스 제공의 안내를 위해 자체적으로 DM, e-DM, TM, 문자, 푸시 알림 등을 발송을 할 수 있습니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>⑤ 회원은 언제든 원할 경우 회사에 제공한 개인정보의 수집과 이용에 관한 동의를 철회할 수 있고, 위 동의의 철회는 해지 신청을 하는 것으로 이루어 집니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>⑥ 이용자의 개인정보보호에 관해서는 정보통신망이용촉진 및 정보보호 등에 관한 법률에 따르고, 사이트에 "개인정보처리방침"을 고지합니다.</li>
                </ol>
            </section>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>제10조 (회사의 의무)</h3>
                <ol className={`${styles['hs-terms-section-list']}`}>
                    <li className={styles['hs-terms-section-list-item']}>① 회사는 특별한 사정이 없는 한 회원이 희망한 서비스 이용 개시일에 서비스를 제공하고 계속적이고 안정적으로 서비스를 제공해야 합니다. 다만, 천재지변이나 비상사태, 해킹 등 부득이한 경우에는 서비스를 일시 중단하거나 영구 중지할 수 있습니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>② 회사는 개인정보 보호를 위한 보안시스템을 구축하고 개인정보취급방침을 공시하고 준수합니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>③ 회사는 이용고객으로부터 제기되는 의견이나 불만이 정당하다고 인정될 경우 적절한 조치를 취해야 하고, 즉시 처리하기 곤란한 경우에는 이용자에게 그 사유와 처리일정을 통보해야 합니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>
                        ④ 서비스 제공이 불가능한 경우
                        <ol className={styles['hs-terms-section-number-list']}>
                            <li className={styles['hs-terms-section-number-item']}>1. 회사는 천재지변 또는 국가비상사태가 발생하거나 발생할 우려가 있는 경우와 회사 설비의 보수 점검 및 교체, 기간 통신 사업자의 전기 통신 서비스가 중단되는 등의 부득이한 사유가 있는 경우에는 서비스의 일부 또는 전부를 제한하거나 중단할 수 있으며, 이 경우 회사는 회원의 손해에 대한 배상책임을 지지 않습니다.</li>
                            <li className={styles['hs-terms-section-number-item']}>2. 서비스 폐지나 휴지 시, 이용자에게 서비스 폐지나 휴지 30일 이전에 서비스 폐지나 휴지 안내 이메일을 발송하여 이용자에게 고지합니다.</li>
                            <li className={styles['hs-terms-section-number-item']}>3. 사업 종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 회사는 이용자에게 통지하고, 회원들의 충전한 금액 등은 현금으로 이용자에게 환불합니다.</li>
                        </ol>
                    </li>
                    <li className={styles['hs-terms-section-list-item']}>⑤ 회사는 발신번호 변작방지와 관련 된 기술적 조치를 취합니다.
                        <ol className={styles['hs-terms-section-number-list']}>
                            <li className={styles['hs-terms-section-number-item']}>1. 본인 명의의 전화번호와 발신번호 비교 확인 시스템 구축</li>
                            <li className={styles['hs-terms-section-number-item']}>2. 문자발송의 경우, 본인 전화번호만 등록 이용하는 기능</li>
                            <li className={styles['hs-terms-section-number-item']}>3. 국제전화의 경우 ‘국제전화입니다’ 안내 메시지 송출</li>
                            <li className={styles['hs-terms-section-number-item']}>4. 국외에서 오는 문자의 경우 [국제발신]문구 표시</li>
                            <li className={styles['hs-terms-section-number-item']}>5. 웹 사이트 발신 문자의 경우 [WEB발신]문구 표시</li>
                        </ol>
                    </li>
                    <li className={styles['hs-terms-section-list-item']}>⑥ 변작방지에 관한 책임 및 의무
                        <ol className={styles['hs-terms-section-number-list']}>
                            <li className={styles['hs-terms-section-number-item']}>1. 전화번호 변작을 통한 사기범죄 예방을 위해 법규를 준수하고, 가입자가 법을 위반시 관련법에따라 조치합니다.</li>
                            <li className={styles['hs-terms-section-number-item']}>2. 가입자는 전기통신서비스를 사용하는 경우 관련 법규를 준수해야 합니다.</li>
                            <li className={styles['hs-terms-section-number-item']}>3. 가입자는 정당하지 않는 방법으로 전화번호를 변경하여 발신하는 경우, 번호변작이 발경 시 관련 법규에 따라 과태료가 부과될 수 있습니다.</li>
                            <li className={styles['hs-terms-section-number-item']}>4. 가입자는 정당하지 않는 방법으로 전화번호를 변경하여 발신하는 경우, 가입자의 통신 서비스가 중지될 수 있습니다.</li>
                        </ol>
                    </li>
                    <li className={styles['hs-terms-section-list-item']}>⑦ 이용고객 중 문자재판매사업자에 대하여 전송자격인증제에 따라 인증이 취소된 사실을 전달받은 경우 문자재판사업자의 서비스 정지, 계약 해지 등의 조치를 취할 수 있다.</li>
                </ol>
            </section>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>제11조 (이용자의 의무)</h3>
                <ol className={`${styles['hs-terms-section-list']}`}>
                    <li className={styles['hs-terms-section-list-item']}>① 회원은 주소, 연락처, 전자우편주소 등 이용계약사항의 변경이 있을 경우 해당 절차를 거쳐 즉시 이를 회사에 알려야 합니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>② 회원은 2015년 4월 16일부터 전기통신사업법 제 84 조의 2(전화번호의 거짓표시 금지 및 이용자 보호)에 따라 본인명의의 발신번호를 사전등록 후 등록된 번호로만 문자 메시지를 발송하여야 합니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>③ 회원은 회사가 제공하는 서비스의 구조나 기능 등을 모방하거나 사전 승인 없이 복제 할 수 없으며 영리목적으로도 사용할 수 없습니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>④ 회원은 회사의 사전 승낙 없이 서비스를 이용하여 영업활동을 할 수 없으며, 회사는 그 영업활동에 대한 책임을 부담하지 않습니다. 또한 회원은 위와 같은 영업활동으로 회사에 손해를 입힐 경우 손해배상책임을 부담합니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>⑤ 회원은 회사의 명시적 동의가 없는 한 서비스의 이용권한, 기타 이용계약상의 지위를 타인에게 양도, 증여, 담보제공 할 수 없습니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>⑥ 회원은 회사 및 제 3자의 지적재산권을 침해해서는 안됩니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>
                        ⑦ 회사는 회원이 다음 각 호의 행위를 할 경우 당해 회원의 서비스 이용제한 등 적절한 제한조치를 할 수 있습니다.
                        <ol className={styles['hs-terms-section-number-list']}>
                            <li className={styles['hs-terms-section-number-item']}>1. 회원가입 신청 또는 회원정보 변경 시 허위내용을 등록하는 행위</li>
                            <li className={styles['hs-terms-section-number-item']}>2. 다른 이용자의 ID, 비밀번호, 연락처를 도용하는 행위</li>
                            <li className={styles['hs-terms-section-number-item']}>3. 이용자 ID를 타인과 거래하는 행위</li>
                            <li className={styles['hs-terms-section-number-item']}>4. 회사의 운영진, 직원 또는 관계자를 사칭하는 행위</li>
                            <li className={styles['hs-terms-section-number-item']}>5. 회사로부터 특별한 권리를 부여 받지 않고 회사의 클라이언트 프로그램을 변경하거나, 회사의 서버를 해킹하거나, 웹사이트 또는 게시된 정보의 일부분 또는 전체를 임의로 변경하는 행위</li>
                            <li className={styles['hs-terms-section-number-item']}>6. 서비스에 위해를 가하거나 고의로 방해하는 행위</li>
                            <li className={styles['hs-terms-section-number-item']}>7. 본 서비스를 통해 얻은 정보를 회사의 사전 승낙 없이 서비스 이용 외의 목적으로 복제하거나, 이를 출판 및 방송 등에 사용하거나, 제 3자에게 제공하는 행위</li>
                            <li className={styles['hs-terms-section-number-item']}>8. 공공질서 및 미풍양속에 위반되는 저속, 음란한 내용의 정보, 문장, 도형, 음향, 동영상을 전송, 게시, 전자우편 또는 기타의 방법으로 타인에게 유포하는 행위</li>
                            <li className={styles['hs-terms-section-number-item']}>9. 모욕적이거나 개인신상에 대한 내용이어서 타인의 명예나 프라이버시를 침해할 수 있는 내용을 전송, 게시, 전자우편 또는 기타의 방법으로 타인에게 유포하는 행위</li>
                            <li className={styles['hs-terms-section-number-item']}>10. 다른 이용자를 희롱 또는 위협하거나, 특정 이용자에게 지속적으로 고통 또는 불편을 주는 행위</li>
                            <li className={styles['hs-terms-section-number-item']}>11. 회사의 승인을 받지 않고 다른 사용자의 개인정보를 수집 또는 저장하는 행위</li>
                            <li className={styles['hs-terms-section-number-item']}>12. 범죄와 결부된다고 객관적으로 판단되는 행위</li>
                            <li className={styles['hs-terms-section-number-item']}>13. 본 약관을 포함하여 기타 회사가 정한 제반 규정 또는 이용 조건을 위반하는 행위</li>
                            <li className={styles['hs-terms-section-number-item']}>14. 무료 메시지 발송을 위해 다수의 아이디로 가입하는 행위</li>
                            <li className={styles['hs-terms-section-number-item']}>15. 부정 가입 및 발신 번호를 조작하여 타인에게 광고 메시지를 발송하는 행위</li>
                            <li className={styles['hs-terms-section-number-item']}>16. 기타 관계법령에 위배되는 행위</li>
                        </ol>
                    </li>
                    <li className={styles['hs-terms-section-list-item']}>⑧ 이용 고객 중 문자재판매사업자는 전송자격 인증을 받고 운영규정을 준수하여야 한다.</li>
                </ol>
            </section>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>제12조 (콘텐츠의 관리)</h3>
                <ol className={`${styles['hs-terms-section-list']}`}>
                    <li className={styles['hs-terms-section-list-item']}>
                        ① 회사는 다음 각 호에 해당하는 콘텐츠나 자료를 사전통지 없이 삭제, 이동하거나 등록 거부할 수 있습니다.
                        <ol className={styles['hs-terms-section-number-list']}>
                            <li className={styles['hs-terms-section-number-item']}>1. 다른 회원 또는 제 3자에게 심한 모욕을 주거나 명예를 손상시키는 내용인 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>2. 공공질서 및 미풍양속에 위반되는 내용을 유포하거나 링크시키는 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>3. 불법복제 또는 해킹을 조장하는 내용인 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>4. 범죄와 결부된다고 객관적으로 인정되는 내용일 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>5. 다른 이용자 또는 제 3자의 저작권 등 기타 권리를 침해하는 내용인 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>6. 회사에서 규정한 게시물 원칙에 어긋나거나, 게시판 성격에 부합하지 않는 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>7. 기타 관계법령에 위배된다고 판단되는 경우</li>
                        </ol>
                    </li>
                    <li className={styles['hs-terms-section-list-item']}>② 회사는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타 회사정책 및 관련법에 위반되는 경우에는 관련법에 따라 해당 콘텐츠에 대해 임시조치 등을 취할 수 있다.</li>
                    <li className={styles['hs-terms-section-list-item']}>③ 본 조에 따른 세부절차는 정보통신망법 및 저작권법이 규정한 범위 내에서 회사가 정한 게시중단 절차에 따른다.</li>
                </ol>
            </section>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>제13조 (콘텐츠에 대한 저작권)</h3>
                <ol className={`${styles['hs-terms-section-list']}`}>
                    <li className={styles['hs-terms-section-list-item']}>① 사이트에 제출한 콘텐츠의 저작권은 게시한 회원에게 귀속됩니다. 다만 회원은 서비스를 통해 콘텐츠를 제출함으로써 회사에 대하여 게시물에 대한 이용, 복사, 복제, 처리, 각색, 변경, 공개, 전송, 게재 또는 배포할 수 있는 독점적이며 무상의 저작사용권(2차 저작사용권을 허여 할 수 있는 권리 포함)을 부여한 것으로 간주된다. 만일 위 게시물이 제3자의 지적재산권을 침해할 경우의 모든 책임은 콘텐츠를 등록한 회원이 부담하며, 이로 인해 회사가 타인으로부터 손해배상청구 등을 받게 될 경우 회원은 회사의 면책을 위해 노력해야 하고, 회사가 면책되지 않을 경우 회사의 모든 손해를 배상하여야 합니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>② 회원이 사이트에 제출하는 콘텐츠는 검색결과 내지 서비스 및 관련 프로모션 등에 노출될 수 있으며, 해당 노출을 위해 필요한 범위 내에서는 일부 수정, 복제, 편집되어 게시될 수 있다. 이 경우, 회사는 저작권법 규정을 준수하며, 회원은 언제든지 콘텐츠에 대해 삭제, 검색결과 제외, 비공개 등의 조치를 취하거나 회사에 요청할 수 있다.</li>
                    <li className={styles['hs-terms-section-list-item']}>③ 회원은 서비스를 이용하여 취득한 정보를 가공, 판매하는 행위 등 서비스에 게재된 자료를 영리목적으로 이용하거나 제3자에게 개인을 식별할 수 있는 형태로 이용하게 할 수 없습니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>④ 회사는 회원이 게시하거나 등록하는 서비스 내의 내용물, 게시물이 전조 각 호에 해당된다고 판단할 경우 이를 삭제하거나 등록 거부할 수 있습니다.</li>
                </ol>
            </section>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>제14조 (광고 거래 및 공유 마케팅)</h3>
                <ol className={`${styles['hs-terms-section-list']}`}>
                    <li className={styles['hs-terms-section-list-item']}>① 회사가 제공하는 서비스를 이용하여 회원 참여를 통한 광고 상품 거래가 가능하며, 등록 제반 정보(법인명, 업종, 회사소개, 보유 회원수)는 공유 될 수 있습니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>② 회원간 발생하는 광고 상품 거래 및 게재에 따라 발생하는 모든 손실과 손해에 대해 회사는 책임을 지지 않습니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>③ 회원은 회사가 제공하는 서비스에 따라 거래 된 광고를 수락 또는 거부할 수 있습니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>④ 회사는 회원 상호간 공유 마케팅 효과를 높이기 위한 각종 활동을 할 수 있습니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>⑤ 회사는 회원 상호간에 이루어지는 광고 상품 거래 과정에 있어 플랫폼 제공자로서의 역할만 수행합니다, 거래 과정에 필요한 의사 결정 및 대금 지급, 집행 관련 책임은 회원 당사자에게 귀속됩니다.</li>
                </ol>
            </section>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>제15조 (서비스 이용의 제한 및 정지)</h3>
                <ol className={`${styles['hs-terms-section-list']}`}>
                    <li className={styles['hs-terms-section-list-item']}>① 회사는 이용자가 이 약관의 의무를 위반하거나 서비스의 정상적인 운영을 방해한 경우 서비스 이용을 제한하거나 정지할 수 있습니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>② 회사는 전항에도 불구하고 주민등록법을 위반한 명의도용 및 결제도용, 저작권법을 위반한 불법프로그램의 제공 및 운영방해, 정보통신망법을 위반한 스팸메세지 및 불법통신, 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법을 위반한 경우에는 즉시 영구이용정지를 할 수 있습니다. 본 항에 따른 서비스 이용정지 시 서비스 내의 잔액, 마일리지, 쿠폰, 혜택 및 권리 등도 모두 소멸되며 회사는 이에 대해 별도로 보상하지 않습니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>
                        ③ 회사는 이용자가 다음 중 하나에 해당하는 경우 1개월 동안의 기간을 정하여 당해 서비스의 이용을 정지 할 수 있습니다. 다만 이용정지 등의 원인이 된 사유가 완전히 해소되기 전까지는 이용정지 등 조치를 해제하지 아니할 수 있습니다.
                        <ol className={styles['hs-terms-section-number-list']}>
                            <li className={styles['hs-terms-section-number-item']}>1. 방송통신위원회 또는 한국인터넷진흥원 등 관계기관이 불법스팸 또는 피싱메시지 등 불법행위의 전송사실을 확인하여 이용정지를 요청하는 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>2. 회사가 제공한 서비스를 이용하여 전송한 광고성 정보가 불법스팸임이 확인된 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>3. 불법스팸 전송으로 과태료가 부과된 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>4. 대량으로 스팸 정보를 전송하여 회사의 서비스 제공(시스템)에 장애를 야기하거나 야기할 우려가 있는 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>5. 회원이 전송하는 광고의 수신자가 스팸으로 신고하는 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>6. 회사가 회원에게 제공하는 서비스가 불법스팸 전송에 이용되고 있는 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>7. 이용자가 제11조(이용자의 의무) 제2항을 위반하여 발신번호를 변작하는 등 거짓으로 표시한 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>8. 미래창조과학부장관 또는 한국인터넷진흥원 등 관련 기관이 발신번호 변작 등을 확인하여 이용 정지를 요청하는 경우</li>
                        </ol>
                    </li>
                </ol>
            </section>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>제16조 (계약해지)</h3>
                <ol className={`${styles['hs-terms-section-list']}`}>
                    <li className={styles['hs-terms-section-list-item']}>① 회원은 이용계약을 해지 하고자 할 때 본인이 직접 서비스를 통하여 신청할 수 있으며 회사는 관련법 등이 정하는 바에 따라 이를 즉시 처리하여야 합니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>
                        ② 회사는 회원이 다음 각 호에 해당할 경우에는 회원의 동의 없이 이용계약을 해지할 수 있으며 그 사실을 회원에게 통지합니다. 다만 회사가 긴급하게 해지할 필요가 있다고 인정하는 경우나 회원의 귀책사유로 인하여 통지할 수 없는 경우에는 지체 없이 사후 통지로 대체 할 수 있습니다.
                        <ol className={styles['hs-terms-section-number-list']}>
                            <li className={styles['hs-terms-section-number-item']}>1. 회원이 이 약관을 위반하고 일정 기간 이내에 위반 내용을 해소하지 않는 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>2. 제11조 (이용자의 의무) 규정을 위반한 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>3. 제15조 (서비스 이용의 제한 및 정지) 규정에 의하여 이용정지를 당한 이후 1년 이내에 이용정지 사유가 재발한 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>4. 방송통신위원회 또는 한국인터넷진흥원 등 관계기관이 스팸메세지 또는 피싱메시지 등 불법행위의 전송사실을 확인하여 계약해지를 요청하는 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>5. 회사가 제공하는 서비스를 이용하여 불특정 다수를 대상으로 무차별적인 스팸을 전송한 사실이 확인된 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>6. 회원이 전송한 광고성 정보에 대해 회사가 한국인터넷진흥원에 불법스팸 유무확인을 요청하여 불법스팸임이 확인된 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>7. 서비스를 이용하여 수신자를 기망하여 회신을 유도하는 행위를 하는 경우</li>
                            <li className={styles['hs-terms-section-number-item']}>8. 회사의 이용요금 등의 납입청구에 대하여 이용자가 이용요금을 체납할 경우</li>
                        </ol>
                    </li>
                </ol>
            </section>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>제17조 (요금 등의 종류)</h3>
                <ol className={`${styles['hs-terms-section-list']}`}>
                    <li className={styles['hs-terms-section-list-item']}>① 회사가 제공하는 유료서비스 이용과 관련하여 이용자가 납부하여야 할 요금은 이용료 안내에 게재한 바에 따릅니다.</li>
                </ol>
            </section>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>제18조 (대금결제 방법)</h3>
                <ol className={`${styles['hs-terms-section-list']}`}>
                    <li className={styles['hs-terms-section-list-item']}>① 요금 납부 시 대금 지급은 무통장 입금, 신용카드 결제, 실시간 계좌이체, 가상계좌 등으로 할 수 있습니다.</li>
                </ol>
            </section>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>제19조 (요금 등의 계산구분)</h3>
                <ol className={`${styles['hs-terms-section-list']}`}>
                    <li className={styles['hs-terms-section-list-item']}>① 요금 등은 서비스 별로 정하는 바에 따라 선납제를 기본으로 합니다. 단, 회사가 정한 일부서비스는 월간 자동결제와 연간 결제를 이용할 수 있습니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>② 서비스가 보장하는 제공범위를 초과한 경우에는 초과분에 대해 추가 요금을 후불로 청구할 수 있습니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>③ 회사가 이벤트 등으로 제공한 무료제공 또는 무료이용기간은 사정에 따라 통보없이 변경 가능하며, 종료된 이후 대금결제 등 유료이용에 대한 의사가 없을 경우 사전 통보 없이 자동으로 중지됩니다.</li>
                </ol>
            </section>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>제20조 (마일리지)</h3>
                <ol className={`${styles['hs-terms-section-list']}`}>
                    <li className={styles['hs-terms-section-list-item']}>① 회사는 서비스의 효율적 이용 및 운영을 위해 마일리지의 일부 또는 전부를 조정 및 회수할 수 있으며 마일리지는 유효기간은 적립된 기준일로부터 1년 입니다. 단, 이벤트를 통한 마일리지는 회사가 정한 기간내에 사용해야 합니다. 기간 내에 사용하지 않은 마일리지는 자동 소멸됩니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>② 마일리지는 회사가 정한 일정 기준 이상의 금액 충전시 또는 메일, 문자 발송시 자동 적립됩니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>③ 메일, 문자(SMS,LMS,MMS) 발송을 통한 마일리지는 실패건 정산이 끝난 후 자동 적립됩니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>④ TAS 푸시 발송, 팩스발송 및 메일쿠폰, 문자쿠폰사용시 마일리지는 적립되지 않습니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>⑤ TAS 사이트에 직접 회원가입하여 TAS 메시지를 사용하는 회원에게만 마일리지가 적립되며 제휴 사이트를 통하여 이용하는 경우에는 마일리지가 적립되지 않습니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>⑥ 마일리지로 TAS 메시지 잔액을 충전할 경우에는 추가 마일리지가 적립되지 않습니다.</li>
                </ol>
            </section>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>제21조 (쿠폰)</h3>
                <ol className={`${styles['hs-terms-section-list']}`}>
                    <li className={styles['hs-terms-section-list-item']}>① 쿠폰은 기본단가 보다 할인된 금액으로 구매할 수 있으며 정상적인 대금 결제를 통해 메일쿠폰, 문자쿠폰(SMS,LMS,MMS)으로 제공됩니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>② 구매한 쿠폰은 취소 및 환불이 되지 않으며 쿠폰 유효기간은 구매일로부터 6개월 입니다. 단, 이벤트를 통해 지급된 쿠폰는 회사가 정한 기간내에 사용해야 합니다. 기간 내에 사용하지 않은 쿠폰은 자동 소멸됩니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>③ 잔액과 쿠폰 모두 충전되어 있을 경우 쿠폰이 먼저 차감되며 쿠폰이 모두 소진될 경우 잔액이 차감됩니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>④ 쿠폰으로 충전되어 있을 경우 실패 건에 대한 환불은 되지 않습니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>⑤ 쿠폰 충전을 통한 마일리지는 적립되지 않습니다.</li>
                </ol>
            </section>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>제22조 (요금 등의 이의신청)</h3>
                <ol className={`${styles['hs-terms-section-list']}`}>
                    <li className={styles['hs-terms-section-list-item']}>① 이용자는 청구된 요금 등에 대하여 이의가 있는 경우 결제일로부터 2개월 이내에 이의 신청을 할 수 있습니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>② 회사는 제1항의 이의 신청 접수 후 2주 이내에 해당 이의신청의 타당성 여부를 조사하여 그 결과를 이용자 또는 그 대리인에게 통지합니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>③ 부득이한 사유로 인하여 제2항에서 정한 기간 내에 이의신청결과를 통지할 수 없는 경우에는 그 사유와 재지정된 처리기한을 명시하여 이를 이용자 또는 그 대리인에게 통지합니다.</li>
                </ol>
            </section>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>제23조 (요금 등의 반환)</h3>
                <ol className={`${styles['hs-terms-section-list']}`}>
                    <li className={styles['hs-terms-section-list-item']}>① 회사는 요금 등의 과납 또는 오납이 있을 때에는 그 과납 또는 오납된 요금을 반환하고, 회사의 귀책사유로 발생한 경우에는 법정이율로서 적정이자를 함께 반환합니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>② 선납제에 한하여 이용자는 서비스를 이용하기 전 선납된 요금 등의 반환을 요청할 수 있습니다. 이러한 경우 회사는 이용자가 반환을 요청한 시점으로부터 익월 말일까지 이용자가 결제한 지불수단으로 반환요청 요금을 반환합니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>③ 회사는 이용자가 잔액에 대해 환불을 요구할 경우, 재정경제부에서 공시한 '소비자피해보상규정 인터넷콘텐츠업'에서 규정한 정책에 따라 잔액의 10% 공제 후 환급합니다. 단, 7일 이상 이용자는 1개월 이상의 계속적 이용계약에 해당되므로 사용기간이 7일 미만일 경우에만 전액환불이 가능합니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>④ 환불요청금액이 1만원 이상일 때, 환불신청이 가능하며 실제 이용자가 결제한 금액만 환불 가능합니다. 회사에서 무상으로 지급한 보너스 포인트는 환불대상에서 제외됩니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>⑤ 사이트에서 표시되는 환불 가능금액과 실제 환불 가능한 금액에는 차이가 있을 수 있습니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>⑥ 환불신청 금액은 신청일시에 따라 매월 10일, 25일에 입금처리 됩니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>⑦ 이용자는 환불 신청시 타스온 가입자명의 통장사본을 환불신청 페이지 내 제출해주셔야 환불처리 됩니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>⑧ 가입자와 예금주의 이름이 다를 경우 환불 불가합니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>⑨ 관련 법률 위반 또는 불법행위를 한 것으로 판단되거나, 서비스 이용규정 위반이 명백하여 이용자의 계정을 제한하거나 해당 이용자와의 계약을 해지하는 경우, 회사는 충전잔액을 환불하지 않을 수 있습니다. 이 경우 회사는 이용자의 e-mail 또는 핸드폰을 통해 이러한 사실 및 이의제기 방법에 대하여 안내를 할 것이며, 개별적으로 고지된 소정의 절차에 따라 이용자가 자신이 진정한 이용자이거나 중대한 불법행위를 하지 않았다는 것을 소명하는 경우에는 그러하지 않습니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>
                        ⑩ 마케팅 자동화 이용 회원은 아래의 조건에 따릅니다.
                        <ol className={styles['hs-terms-section-number-list']}>
                            <li className={styles['hs-terms-section-number-item']}>1. 연간 결제한 회원이 결제일로부터 7일 이내 환불을 요청한 경우, 이용요금의 50%에 해당하는 금액을 환불합니다.</li>
                            <li className={styles['hs-terms-section-number-item']}>2. 연간 결제한 회원이 결제일로부터 15일 이내 환불을 요청한 경우, 이용요금의 30%에 해당하는 금액을 환불합니다.</li>
                            <li className={styles['hs-terms-section-number-item']}>3. 연간 결제한 회원이 결제 후 15일 이상 경과 시는 환불하지 않습니다.</li>
                            <li className={styles['hs-terms-section-number-item']}>4. 캠페인을 실행하지 않은 경우에 한하며, 한 번 이상 메시지가 발송된 경우에는 환불되지 않습니다.</li>
                        </ol>
                    </li>
                </ol>
            </section>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>제24조 (요금 회수)</h3>
                <ol className={`${styles['hs-terms-section-list']}`}>
                    <li className={styles['hs-terms-section-list-item']}>① 기 충전한 요금을 60개월이 경과하는 동안 단 한번도 사용하지 않은 경우, 이용자에게 충전된 요금은 자동으로 소멸됩니다. (상사채권 소멸시효)</li>
                    <li className={styles['hs-terms-section-list-item']}>② 요금의 추가적인 적립 또는 요금에 대한 사용이 있을 경우 해당 적립/사용일자를 기준으로 60개월간 다시 유효하게 됩니다.</li>
                </ol>
            </section>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>제25조 (손해배상)</h3>
                <ol className={`${styles['hs-terms-section-list']}`}>
                    <li className={styles['hs-terms-section-list-item']}>① 회사가 제공하는 서비스로 인하여 회원에게 손해가 발생하는 경우 회사는 그 손해가 회사의 고의 또는 중과실에 의한 경우에 한하여 통상손해의 범위에서 손해배상책임을 부담합니다, 다만 손해배상 금액은 회원의 월 서비스 이용 금액을 초과하지 않습니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>② 이용자가 불법으로 이용요금 등을 사용할 경우에는 관련 법률에 따라 처벌 받을 수 있으며 회사에 손해를 입혔을 경우에는 피해 금액의 2배에 해당하는 손해 배상금을 청구할 수 있습니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>③ 회사는 이용자가 서비스를 이용함에 있어 악용하거나 또는 스팸성 메시지 발송 등으로 회사에 손해를 입혔을 경우에는 피해 금액에 2배에 해당하는 손해 배상금을 청구할 수 있습니다.</li>
                </ol>
            </section>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>제26조 (면책조항)</h3>
                <ol className={`${styles['hs-terms-section-list']}`}>
                    <li className={styles['hs-terms-section-list-item']}>① 회사는 천재지변, 전쟁 및 기타 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 대한 책임이 면제됩니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>② 회사는 기간통신 사업자가 전기통신 서비스를 중지하거나 정상적으로 제공하지 아니하여 손해가 발생한 경우 책임이 면제됩니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>③ 회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 발생한 손해에 대한 책임이 면제됩니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>④ 회사는 회원의 귀책사유로 인한 서비스 이용의 장애 또는 손해에 대하여 책임을 지지 않습니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>⑤ 회사는 이용자의 컴퓨터 오류에 의해 손해가 발생한 경우, 또는 회원이 신상정보 및 전자우편 주소를 부실하게 기재하여 손해가 발생한 경우 책임을 지지 않습니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>⑥ 회사는 회원이 서비스를 이용하여 기대하는 수익을 얻지 못하거나 상실한 것에 대하여 책임을 지지 않습니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>⑦ 회사는 회원이 서비스를 이용하면서 얻은 자료로 인한 손해에 대하여 책임을 지지 않습니다. 또한 회사는 회원이 서비스를 이용하며 타 회원으로 인해 입게 되는 정신적 피해에 대하여 보상할 책임을 지지 않습니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>⑧ 회사는 회원이 서비스에 게재한 각종 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 대하여 책임을 지지 않습니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>⑨ 회사는 이용자 상호간 및 이용자와 제 3자 상호 간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며, 이로 인한 손해를 배상할 책임도 없습니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>⑩ 본 약관 또는 추가약관에 명시되지 않은 한 회사, 또는 회사의 공급자나 판매자는 서비스와 관련하여 어떠한 구체적인 약정도 하지 않습니다. 예를 들어, 회사는 서비스에 속한 콘텐츠, 서비스의 특정 기능, 서비스의 신뢰성, 이용가능성 또는 귀하의 요구를 충족할 능력에 대하여 어떠한 약정도 하지 않습니다. 회사는 서비스를 ‘있는 그대로’ 제공합니다.</li>
                </ol>
            </section>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>제27조 (재판권 및 준거법)</h3>
                <ol className={`${styles['hs-terms-section-list']}`}>
                    <li className={styles['hs-terms-section-list-item']}>① 이 약관에 명시되지 않은 사항은 전기통신사업법 등 관계법령과 상관습에 따릅니다.</li>
                    <li className={styles['hs-terms-section-list-item']}>② 서비스 이용으로 발생한 분쟁에 대해 소송이 제기되는 경우 회사의 본사 소재지를 관할하는 법원을 관할 법원으로 합니다.</li>
                </ol>
            </section>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>[부칙]</h3>
                <ol className={styles['hs-terms-section-list']}>
                    <li className={styles['hs-terms-section-list-item']}>제1조 (변경일) 이 약관은 2024년 10월 31일 변경한다.</li>
                    <li className={styles['hs-terms-section-list-item']}>제2조 (시행일) 이 약관은 2024년 11월 1일부터 시행한다.</li>
                </ol>
            </section>
        </>
    );
}

export default HsTermsService;