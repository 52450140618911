import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollToTop from './components/hooks/ScrollToTop';
import './styles/HsCommon.scss';

import HsLayout from './components/layout/HsLayout';
import HsTermsLayout from './components/layout/HsTermsLayout';
import HsIndex from './pages/HsIndex';
import HsCompany from './pages/HsCompany';
import HsTms from './pages/HsTms';
import HsTermsService from "./pages/HsTermsService";
import HsTermsPrivacy from './pages/HsTermsPrivacy';
import HsTermsPrivacy200416 from './pages/HsTermsPrivacy200416';
import HsTermsPrivacy200716 from './pages/HsTermsPrivacy200716';

function App() {

    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<HsLayout />}>
                    <Route index element={<HsIndex />} />
                    <Route path="company" element={<HsCompany />} />
                    <Route path="tms" element={<HsTms />} />
                </Route>
                <Route path="terms/*" element={<HsTermsLayout/>}>
                    <Route path="service" element={<HsTermsService/>} />
                    <Route path="privacy" element={<HsTermsPrivacy />} />
                    <Route path="hstermsprivacy200416" element={<HsTermsPrivacy200416 />} />
                    <Route path="hstermsprivacy200716" element={<HsTermsPrivacy200716 />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;