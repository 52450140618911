import React, { useState, useEffect } from 'react';
import Input from '../../components/common/Input';
import Textarea from '../../components/common/Textarea';
import { useStore } from 'react-redux';

function PopupConsult ({ ...props }) {
    const [popupData, setPopupData] = useState({
        company: '',
        name: '',
        contact: '',
        email: '',
        content: ''
    });
    const store = useStore();
    
    useEffect(() => {
        return (() => {
            store.dispatch({ type: 'SET_CONSULT_DATA', payload: {
                company: '',
                name: '',
                contact: '',
                email: '',
                content: ''
            }});
        })
    }, []);

    function handleSetPopupData(name, value) {
        popupData[name] = value;
        setPopupData({...popupData});
        setTimeout(() => {
            store.dispatch({ type: 'SET_CONSULT_DATA', payload: popupData });
        });
    }

    return (
            <>
                <Input
                    inputLabel="회사명/소속"
                    inputId="company"
                    inputType="text" 
                    setValue={(value) => handleSetPopupData('company', value)}
                />
                <Input
                    inputLabel="성함/직책"
                    inputId="name"
                    inputType="text" 
                    setValue={(value) => handleSetPopupData('name', value)}
                />
                <Input
                    inputLabel="연락처"
                    inputId="contact"
                    inputType="number" 
                    setValue={(value) => handleSetPopupData('contact', value)}
                />
                <Input
                    inputLabel="메일주소"
                    inputId="email"
                    inputType="text" 
                    setValue={(value) => handleSetPopupData('email', value)}
                />
                <Textarea
                    textareaLabel="내용"
                    textareaId="content"
                    textareaHeight="100px" 
                    setValue={(value) => handleSetPopupData('content', value)}
                />
            </>
    );
}

export default PopupConsult;