import React from 'react';
import styles from '../../styles/components/common/Button.module.scss';


function Button({ buttonType, buttonPrimary, buttonLabel, ...props }) {
  function handleClick() {
    props.onClick && props.onClick();
  }
  return (
    <>
        <button type={buttonType} 
          className={`${styles['hs-button']} ${buttonPrimary ? styles['primary'] : styles['outline'] }`} 
          style={props.style}
          onClick={handleClick}
        >
            {buttonLabel}
        </button>
    </>
  );
}

export default Button;