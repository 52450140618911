const initialState = {
    consultData: {}
  };
  
  const popupReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_CONSULT_DATA":
        return { 
          consultData: action.payload 
        };
      default:
        return state;
    }
  };
  
  export default popupReducer;