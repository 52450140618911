import React, { useState, useEffect, useRef, useCallback } from 'react';
import Popup from '../components/common/popup/Popup';
import Popup220318 from '../assets/popup/Popup220318';
import PopupConsult from '../assets/popup/PopupConsult';
import $ from 'jquery';

import styles from '../styles/pages/HsIndex.module.scss';

import {
    BK, BM, BMW, CA, 
    CJ, EM, GS, HD, 
    HI, LG, LO, LT,
    MA, NH, NT, SB,
    SK, SS, ST
} from '../assets/images/customers';
import { useStore } from 'react-redux';

import { Autoplay, Navigation, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
function HsIndex() {
    const [showPopup, setShowPopup] = useState(false);
    const [showConsultPopup, setShowConsultPopup] = useState(false);
    const [noticeList, setNoticeList] = useState([]);
    const [insightList, setInsightList] = useState([]);
    const { innerWidth } = window;
    const isMobile = innerWidth <= 430;
    const [custCnt] = useState(innerWidth >= 1600 ? 11 : isMobile ? 3 : 9);
    const [insightCnt] = useState(window.innerWidth < 1600 ? 3 : 4);
    let currentSectionIndex = 0;
    let sections;
    // 가로너비가 430보다 작을경우
    const custListTop = [
        SS, HD, LT, BK, MA, HI, LG, NT, NH, GS, SS, HD, LT, BK, MA, HI, LG, NT, NH, GS
    ];
    const custListBottom = [
        SK, BMW, LO, CJ, EM, BM, ST, CA, SB, SK, BMW, LO, CJ, EM, BM, ST, CA, SB
    ];
    // const firstList = [
    //    SS, HD, LT, BK, MA, HI, LG, NT, NH, GS, SS, HD, LT, BK, MA, HI, LG, NT, NH, GS
    // ];
    // const secondList = [
    //     SK, BMW, LO, CJ, EM, BM, ST, CA, SB, SK, BMW, LO, CJ, EM, BM, ST, CA, SB
    // ];
    const serviceList = [
        {
            name: 'targeting',
            title: ['CRM 마케팅 자동화', 'TasOn MA'],
            description: '웹/앱 방문 고객의 행동 데이터, 관심 키워드 기반으로 마케팅 실행, 고객 관심사에 따라 개인화 메시지를 실시간으로 발송합니다.',
            link: 'https://tma.tason.com/landing/main',
            style: {
                width: '140px',
            }
        },
        {
            name: 'tason',
            title: ['통합 메시징 서비스', 'TasOn'],
            description: '고객의 온라인 행동 데이터 수집부터 캠페인 실행, 성과분석까지 자동화 됩니다.',
            link: 'https://www.tason.com',
            style: {
                width: '140px',
            }
        },
        {
            name: 'tms',
            title: ['구축형 빅데이터 마케팅 솔루션', 'TMS Enterprise Solution'],
            description: '이메일, 푸시, 문자, 카카오비즈 메시지 등의 채널 통합과 실시간 데이터를 기반으로 마케팅 자동화를 지원합니다.',
            link: 'https://humuson.com/tms/',
            style: {
                width: '206px',
            }
        },
        {
            name: 'hcloud',
            title: ['클라우드형 통합메시징 솔루션', 'TMS Cloud'],
            description: '웹/앱 방문 고객의 행동 데이터, 관심 키워드 기반으로 마케팅 실행, 고객 관심사에 따라 개인화 메시지를 실시간으로 발송합니다.',
            link: 'https://tason.com',
            style: {
                width: '233px',
            }
        },
    ];

    const store = useStore();

    useEffect(() => {
        const { hash } = window.location;
        const section = document.getElementById(hash.replace('#', ''));
        if (section) {
            setTimeout(() => {
                section.scrollIntoView({ behavior: 'smooth', block: 'start' })
            }, 100);
        }
        // requestNoticeList();
        setInsightListData();
        setInteractionBottom()
        setScrollHandler();
        return () => {
            window.removeEventListener("wheel", handleScroll, { passive: false });
            document.removeEventListener("onTopButtonClicked", handleTopButtonClick);
        };
    }, []);

    const setScrollHandler = () => {
        const $html = $("html");
        $html.animate({ scrollTop : 0 }, 10);
        sections = document.querySelectorAll('section');
        let posTop = 0;
        if (!isMobile) {
            window.addEventListener("wheel", handleScroll, { passive : false });
        }
        document.addEventListener('onTopButtonClicked', handleTopButtonClick);
    }

    const setInteractionBottom = () => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting && !entry.target.classList.contains('to-bottom-visible')) {
              entry.target.classList.add('to-bottom-visible');
            }
          });
        }, {
          threshold: 0.1 // 10% 요소가 보이면 애니메이션 시작
        });

        // 관찰 대상 요소 선택 및 관찰 시작
        const elements = document.querySelectorAll('.hs-section.animate.to-bottom');
        elements.forEach(el => {
          observer.observe(el);
        });
    }
    const handleScroll = (e) => {
        e.preventDefault();
        const $html = $("html");
        const { deltaY } = e;

        if ($html.is(":animated")) {
            return;
        }
        if (deltaY > 0 && sections.length - 1 !== currentSectionIndex) {
            currentSectionIndex += 1;
        } else if (deltaY < 0) {
            if (currentSectionIndex === 0) {
                return;
            }
            currentSectionIndex -= 1;
        }
        const posTop = sections[currentSectionIndex].offsetTop - 72; // padding값 72
        $html.animate({ scrollTop: currentSectionIndex > 0 ? posTop : 0 });
    };

    const handleTopButtonClick = () => {
        currentSectionIndex = 0;
    };

    const setInsightListData = () => {
        const data = [
            {
                title: '데이터로 찾은, 2024 블랙프라이데이 성공방법',
                link: 'https://blog.tason.com/wordpress/%eb%8d%b0%ec%9d%b4%ed%84%b0%eb%a1%9c-%ec%b0%be%ec%9d%80-2024-%eb%b8%94%eb%9e%99%ed%94%84%eb%9d%bc%ec%9d%b4%eb%8d%b0%ec%9d%b4-%ec%84%b1%ea%b3%b5%eb%b0%a9%eb%b2%95/'
            },
            {
                title: '한눈에 보는 2025 트렌드 키워드 (트렌드코리아2025)',
                link: 'https://blog.tason.com/wordpress/2025-%ed%8a%b8%eb%a0%8c%eb%93%9c-%ec%bd%94%eb%a6%ac%ec%95%84/'
            },
            {
                title: '연간 2,000만원 절감, 연합뉴스의 성공적인 발송 시스템 전환',
                link: 'https://blog.tason.com/wordpress/%ec%97%b0%ea%b0%84-2000%eb%a7%8c%ec%9b%90-%ec%a0%88%ea%b0%90-%ec%97%b0%ed%95%a9%eb%89%b4%ec%8a%a4%ec%9d%98-%ec%84%b1%ea%b3%b5%ec%a0%81%ec%9d%b8-%eb%b0%9c%ec%86%a1-%ec%8b%9c%ec%8a%a4%ed%85%9c/'
            },
            {
                title: '격변하는 이커머스 트렌드에서 살아남는 방법',
                link: 'https://blog.tason.com/wordpress/%ea%b2%a9%eb%b3%80%ed%95%98%eb%8a%94-%ec%9d%b4%ec%bb%a4%eb%a8%b8%ec%8a%a4-%ed%8a%b8%eb%a0%8c%eb%93%9c%ec%97%90%ec%84%9c-%ec%82%b4%ec%95%84%eb%82%a8%eb%8a%94-%eb%b0%a9%eb%b2%95/'
            },
            {
                title: '모니모는 왜, 휴머스온을 선택했을까?',
                link: 'https://blog.tason.com/wordpress/%eb%aa%a8%eb%8b%88%eb%aa%a8%eb%8a%94-%ec%99%9c-%ed%9c%b4%eb%a8%b8%ec%8a%a4%ec%98%a8%ec%9d%84-%ec%84%a0%ed%83%9d%ed%96%88%ec%9d%84%ea%b9%8c/'
            },
            {
                title: 'AI 시대, 마케터를 위한 데이터 기반 사고법',
                link: 'https://blog.tason.com/wordpress/ai-%ec%8b%9c%eb%8c%80-%eb%a7%88%ec%bc%80%ed%84%b0%eb%a5%bc-%ec%9c%84%ed%95%9c-%eb%8d%b0%ec%9d%b4%ed%84%b0-%ea%b8%b0%eb%b0%98-%ec%82%ac%ea%b3%a0%eb%b2%95/'
            },
            {
                title: '사람들이 정말 보고 싶어하는 7가지 콘텐츠',
                link: 'https://blog.tason.com/wordpress/%ec%82%ac%eb%9e%8c%eb%93%a4%ec%9d%b4-%ec%a0%95%eb%a7%90-%eb%b3%b4%ea%b3%a0-%ec%8b%b6%ec%96%b4%ed%95%98%eb%8a%94-7%ea%b0%80%ec%a7%80-%ec%bd%98%ed%85%90%ec%b8%a0/'
            },
            {
                title: '플랫폼 의존 줄이고, 자사몰로 성장하는 방법 (feat.티메프 사태)',
                link: 'https://blog.tason.com/wordpress/%ed%94%8c%eb%9e%ab%ed%8f%bc-%ec%9d%98%ec%a1%b4-%ec%a4%84%ec%9d%b4%ea%b3%a0-%ec%9e%90%ec%82%ac%eb%aa%b0%eb%a1%9c-%ec%84%b1%ec%9e%a5%ed%95%98%eb%8a%94-%eb%b0%a9%eb%b2%95-feat-%ed%8b%b0%eb%a9%94/'
            },
            {
                title: '콘텐츠 AB 테스트 솔루션 ‘TMS AB insight’ 출시',
                link: 'https://blog.tason.com/wordpress/%ec%bd%98%ed%85%90%ec%b8%a0-ab-%ed%85%8c%ec%8a%a4%ed%8a%b8-%ec%86%94%eb%a3%a8%ec%85%98-tms-ab-insight-%ec%b6%9c%ec%8b%9c/'
            },
        ];
        setInsightList(data);

        // [2024-05-09] Swiper 네비게이션 버튼 위치 커스터마이징
        const noticeListEl = document.querySelector('.insight-list');
        const prevEl = noticeListEl.querySelector('.swiper-button-prev');
        const nextEl = noticeListEl.querySelector('.swiper-button-next');
        const target = document.querySelector('.insight-swiper');
        setTimeout(() => {
            prevEl.parentElement.removeChild(prevEl);
            nextEl.parentElement.removeChild(nextEl);
            target.appendChild(prevEl);
            target.appendChild(nextEl);
        }, 100);
    }

    const requestNoticeList = () => {
        const reqCnt = 5;
        const url = `https://www.tason.com/postman/notice?cnt=${reqCnt}`;
        fetch(url).then(res => res.json()).then((res) => {
            // [2024-05-03] Swiper를 위한 배열 2배로 늘리기위한 처리
            setNoticeList(res.concat(res));
        });

        // [2024-05-03] Swiper 네비게이션 버튼 위치 커스터마이징
        const noticeListEl = document.querySelector('.notice-list');
        const prevEl = noticeListEl.querySelector('.swiper-button-prev');
        const nextEl = noticeListEl.querySelector('.swiper-button-next');
        const target = document.querySelector('.notice-swiper');
        setTimeout(() => {
            prevEl.parentElement.removeChild(prevEl);
            nextEl.parentElement.removeChild(nextEl);
            target.appendChild(prevEl);
            target.appendChild(nextEl);
        }, 100);
    }

    const handleShowConsultPopup = () => {
        setShowConsultPopup(true);
    }

    function handleDismissClick() {
        localStorage.setItem('isPopupShown', false);
        setShowPopup(false);
    }

    function handleDismissClickForDay() {
        const today = new Date().toISOString().slice(0, 10);
        localStorage.setItem('isPopupShownToday', today);
        setShowPopup(false);
    }

    function handleClose() {
        setShowConsultPopup(false);
    }

    function handleTodayClose() {
        setShowPopup(false);
    }

    function requestConsult(formData) {
        const { company, name, contact, email, content } = formData;

        if(!company || company.trim() === '') {
            alert('회사명/소속을 입력해주세요.');
            return;
        } else if(!name || name.trim() === '') {
            alert('성함/직책을 입력해주세요.');
            return;
        } else if(!contact || contact.trim() === '') {
            alert('연락처를 입력해주세요.');
            return;
        } else if(!email || email.trim() === '') {
            alert('메일주소를 입력해주세요.');
            return;
        } else if(!content || content.trim() === '') {
            alert('문의내용을 입력해주세요.');
            return;
        }

        let keyword = '';
        const type = 1; // qna일 경우 1, 서비스 소개서 신청일 경우 2
        const volume = '0';
        const params = {
            type,
            company,
            name,
            contact,
            email,
            content,
            keyword,
            volume
        }
        const url = "https://imc.humuson.com/api/contact/v1/sendMail";
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        })
        .then(response => response.json())
        .then((res) => {
            alert(res.message);
            setShowConsultPopup(false);
        })
        .catch(error => {
            // 오류 처리
        });
    }

    function handleClick() {
        const { consultData } = store.getState().popupDataReducer;
        requestConsult(consultData);
    }

    return (
        <>
            <section className={`${styles['hs-visual-section']}`}>
                <div className={styles['hs-visual-section-inner-logo']}>
                    <h2 className="blind">디지털 마케팅 플랫폼, 휴머스온</h2>
                    <div className={styles['hs-visual-section-inner']}>
                        <h2 className={styles['hs-visual-section-title']}>빅데이터 마케팅 플랫폼 <span className={styles['point']}>휴머스온</span> <span className="blind">휴머스온</span></h2>
                        <ul className={styles['hs-visual-section-list']}>
                            <li className={styles['hs-visual-section-item']}>자사가 보유한 빅데이터를 기반으로</li>
                            <li className={styles['hs-visual-section-item']}>통합메시징 솔루션ㆍAI마테크 서비스를 운영하며 기업의 초개인화 마케팅을 지원합니다.</li>
                        </ul>
                        <button className={`${styles.primary} ${styles.dark}`} onClick={() => window.open('https://www.tason.com/to/customer_collect', '_blank')}>
                            <a>
                                도입 문의하기
                            </a>
                        </button>
                        <div className="hs-section-inner">
                            <Swiper
                                loop={true}
                                modules={[Autoplay, A11y]}
                                autoplay={{
                                    delay: 0,
                                    disableOnInteraction: false,
                                }}
                                slidesPerView={custCnt}
                                speed={5000}
                                className="swiper-container relative far-top"
                            >
                                <div>
                                    {
                                        custListTop.map((elem, index) => (
                                            <SwiperSlide key={`top_${index}`} className="swiper-slide">
                                                <img src={elem}></img>
                                            </SwiperSlide>
                                        ))
                                    }
                                </div>
                            </Swiper>
                            <Swiper
                                loop={true}
                                modules={[Autoplay, A11y]}
                                dir={'rtl'}
                                autoplay={{
                                    delay: 0,
                                    disableOnInteraction: false,
                                }}
                                slidesPerView={custCnt}
                                speed={5000}
                                className="swiper-container relative far-top"
                            >
                                <div>
                                    {
                                        custListBottom.map((elem, index) => (
                                            <SwiperSlide key={`bottom_${index}`} className="swiper-slide">
                                                <img src={elem}></img>
                                            </SwiperSlide>
                                        ))
                                    }
                                </div>
                            </Swiper>
                            <ul className="blind">
                                <li>삼성</li>
                                <li>현대</li>
                                <li>한국은행</li>
                                <li>미래에셋증권</li>
                                <li>한화투자증권</li>
                                <li>LG</li>
                                <li>국세청</li>
                                <li>NH투자증권</li>
                                <li>GS</li>
                                <li>SK증권</li>
                                <li>BMW</li>
                                <li>LOREAL</li>
                                <li>Homeplus</li>
                                <li>CJ</li>
                                <li>emart</li>
                                <li>배달의 민족</li>
                                <li>STARBUCKS</li>
                                <li>CAFE24</li>
                                <li>SANDBOX</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="hs-section wide full-size light-purple1">
                <h2 className="blind">통합 채널 메시징 솔루션 TasOn</h2>
                <div className="hs-section-inner">
                    <h2 className={styles['hs-section-inner-title']}>
                        통합 채널 메시징 솔루션 TasOn
                    </h2>
                    <p className={styles['hs-section-inner-sub-title']}>
                        문자, 카카오, 이메일, 네이버, 웹푸시 
                        <br />
                        분산된 메시징 채널을 하나의 플랫폼에서 통합 발송,관리, 분석까지 지원합니다. 
                    </p>
                    <button className={'btn btn-success'} onClick={() => window.open(serviceList[1].link, '_blank')}>
                        <a>
                            서비스 바로가기
                        </a>
                    </button>
                    <div className='hs-section-inner-content tason-main'>
                    </div>
                </div>
            </section>
            <section className="hs-section wide full-size deep-purple1">
                <h2 className="blind">CRM 마케팅 자동화 TasOn MA</h2>
                <div className="hs-section-inner">
                    <h2 className={styles['hs-section-inner-title']}>
                        CRM 마케팅 자동화 TasOn MA
                    </h2>
                    <p className={styles['hs-section-inner-sub-title']}>
                        AI 기반 개인화 마케팅 SaaS 솔루션 TMA는
                        <br />
                        고객 개인화 여정에 필요한 모든 기능을 제공합니다.
                    </p>
                    <button className={'btn btn-primary'} onClick={() => window.open(serviceList[0].link, '_blank')}>
                        <a>
                            30일 무료체험
                        </a>
                    </button>
                    <div className='hs-section-inner-content ma-main'>
                    </div>
                </div>
            </section>
            <section className="hs-section wide full-size light-purple2">
                <h2 className="blind">기업 맞춤 통합 메시징 서비스 TMS</h2>
                <div className="hs-section-inner">
                    <h2 className={styles['hs-section-inner-title']}>
                        기업 맞춤 통합 메시징 서비스 TMS
                    </h2>
                    <p className={styles['hs-section-inner-sub-title']}>
                        이메일/푸시/문자/카카오비즈 메시지 채널 통합과 
                        <br />
                        실시간 데이터를 기반으로 마케팅 자동화를 지원하는 솔루션입니다.
                    </p>
                    <button className={'btn btn-primary-light'} onClick={() => window.open(serviceList[2].link, '_blank')}>
                        <a>
                            서비스 바로가기
                        </a>
                    </button>
                    <div className='hs-section-inner-content tms-main'>
                    </div>
                </div>
            </section>
            <section className="hs-section wide slide">
                <h2 className="blind">컨설팅</h2>
                <div className="hs-section-inner">
                    <h2 className={`${styles['hs-section-title']} ${styles.dark2}`}>비지니스 성공을 위한 1:1 컨설팅</h2>
                    <p className={`${styles['hs-section-sub-title']} ${styles.dark4}`}>1인기업부터 엔터프라이즈까지 고객 개인화 여정에 필요한 모든 것</p>
                    <div className={styles['hs-consulting-container']}>
                        <div className={styles['hs-consulting-item']}>
                            <div className={`${styles['img-content']} ${styles.solution}`}></div>
                            <p className={styles.title}>마케팅 솔루션 제안</p>
                            <p className={styles.description}>
                                문제점을 정밀 진단하고 
                                <br />
                                적합한 마케팅 솔루션 세트를 제안합니다.
                            </p>
                        </div>
                        <div className={styles['hs-consulting-item']}>
                            <div className={`${styles['img-content']} ${styles.apply}`}></div>
                            <p className={styles.title}>도입 지원</p>
                            <p className={styles.description}>
                                솔루션의 도입에 필요한 
                                <br />
                                기술 자원, 기본 사용법을 안내합니다.
                            </p>
                        </div>
                        <div className={styles['hs-consulting-item']}>
                            <div className={`${styles['img-content']} ${styles.manage}`}></div>
                            <p className={styles.title}>고객 성공 관리</p>
                            <p className={styles.description}>
                                도입한 솔루션을 효과적으로 활용하여
                                <br />
                                비지니스 성공을 달성할 수 있도록 
                                <br />
                                지속적으로 관리합니다.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="hs-section full-size white1" style={{ padding: '140px 0' }}>
                <h2 className="blind">마케팅 인사이트</h2>
                <div className="hs-section-inner relative">
                    <h2 className={styles['hs-section-inner-title']}>마케팅 인사이트</h2>
                </div>
                <div className="hs-section-inner relative swiper-inner">
                    <div className='insight-swiper'>
                        <Swiper
                            loop={true}
                            navigation={true}
                            modules={[Navigation]}
                            slidesPerView={insightCnt}
                            spaceBetween={45}
                            className="swiper-container insight-list"
                        >
                            <div>
                                {
                                    insightList.map((insight, index) => (
                                        <SwiperSlide key={`insight_${index}`} className="swiper-slide">
                                            <div className={`${styles['insight-image']} ${styles[`insight-${index}`]}`} 
                                                onClick={() => window.open(insight.link, '_blank')}
                                            />
                                            <div className={styles['insight-item']}>
                                                <p className={styles.title}>
                                                    {
                                                        <a href={insight.link} target='_blank'>
                                                            {insight.title}
                                                        </a>
                                                    }
                                                </p>
                                            </div>
                                        </SwiperSlide>
                                    ))
                                }
                            </div>
                        </Swiper>
                    </div>
                </div>
            </section>
            <section className="hs-section wide introduce">
                <h2 className="blind">도입 문의하기</h2>
                <div className="hs-section-inner">
                    <p className='title'>
                    1등 기업들이 선택한 개인화 마케팅,<br></br> 지금 바로 경험해보세요. 
                    </p>
                    <div>
                        <button onClick={() => window.open('https://www.tason.com/to/customer_collect', '_blank')}>
                            <a>
                                도입 문의하기
                            </a>
                        </button>
                    </div>
                </div>
            </section>
            {showPopup && (
                <Popup
                    contentTitle={"신규 서비스 개편 안내"}
                    contentComponent={Popup220318}
                    popupConstructor={'Popup220318'}
                    dismissClick={handleDismissClick}
                    dismissClickForDay={handleDismissClickForDay}
                    onClose={handleTodayClose}
                    contentImage
                    contentCheck
                />
            )}

            {
                showConsultPopup &&
                <Popup
                    onClose={handleClose}
                    contentTitle={"문의/상담 신청"}
                    contentComponent={PopupConsult}
                    popupConstructor={'PopupConsult'}
                    buttonPrimary={"보내기"}
                    buttonPrimaryClick={(data) => handleClick(data)}
                />
            }
        </>
    );
}

export default HsIndex;