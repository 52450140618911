import React from "react";
// import { useLocation, useNavigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import styles from '../../styles/components/layout/HsTermsFooter.module.scss'

function HsTermsFooter() {
    const navigate = useNavigate();
    // const location = useLocation();

    const handleBack = () => {
      navigate('/');
      // if (location.state && location.state.from) {
      //   navigate(location.state.from); 
      // } else {
      //   navigate(-1);
      // }
    };

    return (
        <>
        <footer className={styles['hs-term-footer']}>
            <button type="button" onClick={handleBack} className={styles['hs-term-footer-button']}>
                닫기
            </button>
        </footer>
        </>
    );
}

export default HsTermsFooter;