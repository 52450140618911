const SEND_STATE = {
    COMPLETED: '구독하기',
    SENDING: 'please wait...'
}

const METHOD = {
    GET: 'GET',
    POST: 'POST'
}

export {
    SEND_STATE, METHOD
}